import { defineStore } from 'pinia'
import type { AvailableResources } from '~/repository/modules/resources'

export const useResourcesStore = defineStore('resources', () => {
  const { $api } = useNuxtApp()

  const availableResources = ref<AvailableResources>([])

  const loading = ref<boolean>(false)

  const loadAvailableResources = async () => {
    const { data } = await $api.resources.availableResources()
    availableResources.value = (data as unknown as AvailableResources).sort((a, b) => a.label.localeCompare(b.label))
  }

  return {
    loading,
    availableResources,

    loadAvailableResources,
  }
})
